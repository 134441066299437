import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { AuthorizationContext, useAuthorization } from '../context/authorization.context'
import HttpClient from '../services/http/client'
import { Suggestion as TSuggestion, UpdateSuggestion } from '../types/types'
import { Navigate, useParams } from 'react-router-dom'
import classNames from 'classnames'
import Suggestion from '../components/Suggestion'
import {
  QueryClient,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient
} from '@tanstack/react-query'
import { useNotify } from '../context/notify.context'
import { ReactComponent as SearchIcon } from '../media/search-icon.svg'
import { ReactComponent as ShareIcon } from '../media/share-icon.svg'
import { constructThreadShareRoute, createSuggestionsFormRoute } from '../components/routes'
import Client from '../services/http/client'
import { useModal } from '../context/modal.context'
import { constructBaseURL } from '../components/helpers'
import { copyToClipboard } from '../helpers/helpers'

type shareThreadMutation = { userId: string, threadId: string }
type updateSuggestionMutation = { userId: string, threadId: string, suggestionId: string, update: UpdateSuggestion }
type sendSuggestionFeedback = { userId: string, threadId: string, suggestionId: string, type: 'like' | 'dislike' }

const ThreadSuggestions = (): ReactElement => {
  const authorizationContext: AuthorizationContext = useAuthorization()
  const { threadId } = useParams()
  const { notify } = useNotify()
  const { show: showModal } = useModal()
  const queryClient: QueryClient = useQueryClient()

  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState<number>(0)

  const client: HttpClient = useMemo((): Client => new HttpClient(
    process.env.REACT_APP_HTTP_SERVER_ADDRESS ?? 'http://127.0.0.1:8080',
    authorizationContext,
  ), [authorizationContext])

  const {
    data: suggestions,
    isLoading: suggestionsLoading,
    error: suggestionsError,
  } = useQuery(
    {
      queryKey: ['thread_suggestions', authorizationContext.value?.user_id, threadId],
      queryFn: (): Promise<TSuggestion[]> =>
        client.threadSuggestions(authorizationContext.value?.user_id ?? '', threadId ?? ''),
      enabled: !!authorizationContext.value?.user_id && !!threadId,
    }
  )

  const shareThreadMutation: UseMutationResult<string, Error, shareThreadMutation> = useMutation<string, Error, shareThreadMutation>({
    mutationFn: async (args: shareThreadMutation): Promise<string> => {
      const result: string = await client.createThreadShare(args.userId, args.threadId)
      return constructBaseURL() + constructThreadShareRoute(result)
    },
    onError: (): void => {
      notify('Failed to create a shareable link.', 'error')
    }
  })

  const updateSuggestionMutation: UseMutationResult<
      TSuggestion,
      Error,
      updateSuggestionMutation,
      { prevSuggestions: TSuggestion[] }
  > = useMutation<
      TSuggestion,
      Error,
      updateSuggestionMutation,
      { prevSuggestions: TSuggestion[] }
  >({
    mutationFn: async (args: updateSuggestionMutation): Promise<TSuggestion> =>(
      await client.updateSuggestion(
        args.userId,
        args.threadId,
        args.suggestionId,
        args.update
      )
    ),
    onMutate: async (args: updateSuggestionMutation): Promise<{ prevSuggestions: TSuggestion[] }> => {
      await queryClient.cancelQueries({
        queryKey: ['thread_suggestions', args.userId, args.threadId],
      })

      const prevSuggestions: TSuggestion[] = queryClient.getQueryData<TSuggestion[]>(['thread_suggestions', args.userId, args.threadId]) ?? []

      queryClient.setQueryData(
        ['thread_suggestions', args.userId, args.threadId],
        prevSuggestions.map((suggestion: TSuggestion) =>
          suggestion.id === args.suggestionId ? { ...suggestion, ...args.update } : suggestion
        )
      )

      return { prevSuggestions: prevSuggestions }
    },
    onSuccess: (result: TSuggestion, args: updateSuggestionMutation): void => {
      queryClient.setQueryData(['thread_suggestions', args.userId, args.threadId], (prev: TSuggestion[]) =>
        prev.map((prevSuggestion: TSuggestion): TSuggestion =>
          prevSuggestion.id === args.suggestionId ? result : prevSuggestion,
        ),
      )

      notify('Suggestion updated successfully!', 'success')
    },
    onError: (_error: Error, args: updateSuggestionMutation, context): void => {
      if (context?.prevSuggestions) {
        queryClient.setQueryData(['thread_suggestions', args.userId, args.threadId], context.prevSuggestions)
      }

      notify('Failed to update suggestion.', 'error')
    }
  })

  const sendSuggestionFeedbackMutation: UseMutationResult<sendSuggestionFeedback, Error, sendSuggestionFeedback> = useMutation<sendSuggestionFeedback, Error, sendSuggestionFeedback>({
    mutationFn: async (args: sendSuggestionFeedback): Promise<sendSuggestionFeedback> =>
      client.sendSuggestionFeedback(args.userId, args.threadId, args.suggestionId, args.type).then(() => args),
    onSuccess: async (): Promise<void> => {
      notify('Feedback successfully sent, thank you!', 'success')
    },
    onError: (): void => {
      notify('Failed to send suggestion feedback.', 'error')
    }
  })

  useEffect((): void => {
    if (suggestionsError) {
      notify('There was a problem loading your suggestions.', 'error')
    }
  }, [suggestionsError, notify])

  if (suggestionsLoading) {
    return (
      <div className="flex flex-col justify-center items-center h-full">
        <div className="w-[30px] md:w-[40px] rounded-full border-[4px] border-[#e4e0e1] border-r-[#0035ff] spinner"/>

        <div className="mt-4 md:mt-6"/>

        <p className="text-[12px] md:text-[16px]">
          {'Loading...'}
        </p>
      </div>
    )
  }

  if (suggestionsError) {
    return <Navigate to={createSuggestionsFormRoute}/>
  }

  if (!suggestions || suggestions.length === 0) {
    return (
      <div className="flex flex-col h-full items-center justify-center">
        <SearchIcon className="w-[20px] h-[20px] md:w-[25px] md:h-[25px] fill-transparent stroke-[#0035ff]" />

        <div className="mt-6"/>

        <p className="text-[14px] lg:text-[16px]">{'Oops, nothing found.'}</p>

        <div className="mt-2"/>

        <p className="text-[14px] lg:text-[16px] flex whitespace-pre">
          {'Please, try again, or feel free to '}
          <a className="text-[14px] lg:text-[16px] text-[#0035ff] cursor-pointer">{'contact us'}</a>
          {'.'}
        </p>
      </div>
    )
  }

  const updateThreadSuggestion = (suggestionId: string, update: UpdateSuggestion): void => updateSuggestionMutation.mutate({
    userId: authorizationContext.value?.user_id ?? '',
    threadId: threadId ?? '',
    suggestionId: suggestionId,
    update: update,
  })

  const sendSuggestionFeedback = (suggestionId: string, type: 'like' | 'dislike'): void => sendSuggestionFeedbackMutation.mutate({
    userId: authorizationContext.value?.user_id ?? '',
    threadId: threadId ?? '',
    suggestionId: suggestionId,
    type: type,
  })

  const shareThreadButtonClick = async (): Promise<void> => {
    const shareURL: string = await shareThreadMutation.mutateAsync({
      userId: authorizationContext.value?.user_id ?? '',
      threadId: threadId ?? '',
    })

    showModal({
      title: 'Copy your shareable link',
      subtitle: 'Your shareable link was successfully created:',
      description: shareURL,
      submitButtonTitle: 'Copy to clipboard',
      submitButtonClick: async (): Promise<void> => {
        await copyToClipboard(shareURL)
        showModal(null)
        notify('Shareable link successfully copied.', 'success')
      },
    })
  }

  return (
    <div className="flex flex-col h-full relative">
      <div className="flex justify-end sm:hidden">
        <button
          className="flex items-center p-2 rounded-[10px] border-[1px] border-[#e4e0e1] hover:border-[#0035ff] duration-300 ease-in-out"
          onClick={shareThreadButtonClick}
        >
          <ShareIcon className="w-[18px] h-[18px] lg:w-[20px] lg:h-[20px]"/>

          <div className="ml-2"/>

          <p className="text-[12px] lg:text-[13px]">{'Share'}</p>
        </button>
      </div>

      <div className="flex flex-col relative">
        <div className="flex flex-col sm:flex-row sm:justify-center sm:items-stretch w-full relative">
          {suggestions.map(({ title }: TSuggestion, index: number): ReactElement => (
            <div
              key={index}
              className={
                classNames(
                  'flex sm:items-center w-full md:w-auto sm:justify-center sm:max-w-[12rem] first:ml-0 sm:ml-4 xl:ml-6 p-2 text-center cursor-pointer transition border-b-[2px] border-[#e4e0e1]',
                  {
                    'border-b-[#0035ff] text-[#0035ff]': index === activeSuggestionIndex,
                  }
                )
              }
              onClick={(): void => setActiveSuggestionIndex(index)}
            >
              <p className="text-[12px] xl:text-[14px] text-center">
                {title}
              </p>
            </div>
          ))}

          <div className="ml-2 lg:ml-4 2xl:ml-0"/>

          <div className="hidden sm:flex sm:justify-center sm:items-center 2xl:absolute 2xl:right-0">
            <button
              className="flex items-center p-2 rounded-[10px] border-[1px] border-[#e4e0e1] hover:border-[#0035ff] duration-300 ease-in-out"
              onClick={shareThreadButtonClick}
            >
              <ShareIcon className="w-[18px] h-[18px] lg:w-[20px] lg:h-[20px]"/>

              <div className="ml-2"/>

              <p className="text-[12px] lg:text-[13px]">{'Share'}</p>
            </button>
          </div>
        </div>

        <div className="mt-6 xl:mt-10"/>
      </div>

      <div className="flex flex-col sm:overflow-hidden h-full">
        <Suggestion
          key={activeSuggestionIndex}
          value={suggestions[activeSuggestionIndex]}
          update={updateThreadSuggestion}
          sendFeedback={sendSuggestionFeedback}
        />
      </div>
    </div>
  )
}

export default ThreadSuggestions
